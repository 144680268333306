<template>
    <div class="body">
        <!-- banner区域 Start -->
        <div class="banner">
            <m-top-bar></m-top-bar>
        </div>
        <!-- banner区域 End -->

        <!-- switchBar区域 Start -->
        <div class="switchBar">
            <div class="switchBox">工业级32位通信处理器</div>
        </div>
        <div class="line"></div>
        <!-- switchBar区域 End -->

        <!-- container区域 Start -->
        <div class="container">
            <div class="containerBox">
                <img src="../../../../assets/mobile/img/product/communicationProcessor.png" alt="" class="pic">
                <div class="title">工业级32位通信处理器</div>
                <div class="text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;LK-AG500L是基于2G/3G/4G/5G、WIFI、虚拟专网等技术开发的无线网关。产品采用高性能的工业级32位通信处理器和工业级无线模块，以嵌入式实时操操作系统为软件支撑平台，同时支持1个以太网WAN（可配置为LAN口）、2个以太网LAN、1个RS232/RS485接口，能满足工业现场通信的需求。<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;LK-AG500L全面支持中国移动、中国联通、中国电信三大运营商的2G（GPRS/
                    CDMA）、3G（WCDMA/HUUPA/HSPA+/CDMA 2000 1x EVDO）、4G（TDD-LTE/FDD-LTE）、5G（Sub-6/UL
                    MIMO）网络，支持WIFI功能\支持GPS\北斗功能（可选），为用户提供全面的无线广域网和无线局域网服务。<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;该产品已广泛应用于物联网产业链中的M2M行业，如电力、交通、邮政、热力、路灯、油田、金融、快递、传媒、POS自助终端、智能建筑、消防、环境保护、气象、农林、水利、石化等领域。<br>
                </div>
                <div class="title">产品特点</div>
                <div class="text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;采用高性能工业级无线模块。<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;采用高性能工业级32位通信处理器。<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;采用金属外壳，保护等级IP30。<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;宽电源输入（DC5~35VDC）。<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;WDT看门狗设计，保证系统稳定。<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;采用完备的防掉线机制，保证数据终端永远在线。<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;以太网接口内置1.5KV电磁隔离保护。<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;RS232/RS485接口内置15KVESD保护。<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;SIM/UIM卡接口内置15KVESD保护。<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;电源接口内置反相保护和过流、过压保护。<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;天线接口防雷保护（可选）。<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;提供标准RS232（或RS485）、以太网和WIFI接口，可直接连接串口设备、以太网设备和WIFI设备。<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;提供标准有线WAN口（支持标准PPPOE协议），可直接连接ADSL设备。<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;智能型数据终端，上电即可进入数据传输状态。<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;使用方便，灵活，多种工作模式选择。<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;方便的系统配置和维护接口（包括本地和远端WEB和CLI方式）。<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;同时支持挂耳式和35mmDin-rail式安装。<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;支持多种WAN连接方式，包括静态IP，DHCP，PPPOE，2G/3G/4G/5G。<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;支持无线网络和有线WAN双链路智能切换备份功能（可选）。<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;支持VPN（PPTP，L2TP，IPSEC和 GRE）（注：仅VPN版支持）。<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;支持远程管理，SYSLOG、SNMP、TELNET、SSHD，HTTPS等功能。<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;支持本地和远程在线升级，导入导出配置文件。<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;支持NTP，内置RTC。<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;支持国内外多种DDNS。<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;支持MAC地址克隆。<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;WIFI支持802.11b/g/n,支持WIFI AP、AP Client。<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;WIFI支持WEP,WPA，WPA2等多种加密方式。<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;支持多种上下线触发模式，包括短信、电话振铃、串口数据、网络数据触发上/下线模式。<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;支持APN/VPDN。<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;支持多路DHCP server及DHCPclient，DHCP捆绑MAC地址，DDNS，防火墙，NAT，DMZ主机，QoS，流量统计,实时显示数据传输速率等功能。<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;支持TCP/IP、UDP、FTP（可选）、HTTP等多种网络协议。<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;支持SPI防火墙，VPN穿越，访问控制，URL过滤，等功能。<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;支持本地日志存储。<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;支持GPS/北斗功能（可选）。<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;支持双SIM卡（可选）。<br>
                </div>
            </div>
        </div>
        <!-- container区域 End -->

        <!-- baseBar区域 Start -->
        <m-base-bar></m-base-bar>
        <!-- baseBar区域 End -->
    </div>
</template>
    
<script>
import mTopBar from '@/components/mTopBar.vue'
import mBaseBar from '@/components/mBaseBar.vue'

export default {
    name: 'Support',
    components: {
        mTopBar,
        mBaseBar,
    }
}
</script>
  
<style scoped>
.body {
    background-color: #f7f7f7 !important;
}

/* banner区域 */
.banner {
    width: 100%;
    min-height: 2050px;
    background: url(../../../../assets/mobile/img/solution/banner.jpg) center no-repeat;
    background-size: 335%;
}

/* switchBar区域 */
.switchBar {
    padding-top: 50px;
    width: 100%;
    height: 300px;
    text-align: center;
    background-color: #ffffff;
}

.switchBar .switchBox {
    margin: 0 auto;
    text-align: center;
    width: 950px;
    height: 200px;
    font-size: 72px;
    line-height: 200px;
    color: #ffffff;
    border-radius: 30px;
    background-color: #c3a35d;
}

.line {
    margin: 0 auto;
    width: 90%;
    border-bottom: 1px solid #c3a35d;
}

/* container区域 */
.container {
    padding: 50px;
    width: 100%;
    min-height: 500px;
}

.container .containerBox {
    padding: 100px;
    width: 100%;
    min-height: 300px;
    text-align: justify;
    background-color: #ffffff;
}

.container .containerBox .pic {
    margin-left: 320px;
    margin-bottom: 150px;
    width: 1000px;
}

.container .containerBox .title {
    margin-bottom: 50px;
    font-weight: 700;
    font-size: 80px;
}

.container .containerBox .text {
    margin-bottom: 50px;
    font-size: 70px;
    color: #999;
}</style>